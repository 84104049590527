import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },

    // breakpoints: {
    //     sm: '40em',
    //     md: '52em',
    //     lg: '64em',
    //     xl: '80em',
    // },
    fonts: {
        body: `'Inter', 'Raleway', sans-serif`,
        heading: `'Archivo', 'Inter', 'Raleway', sans-serif`,
        mono: `'Fira Code', 'Menlo', 'Monaco', 'Consolas', monospace`,
    },
    styles: {
        global: {
            // styles for the `body`
            body: {
                bg: '#0B0B0B',
                fontWeight: 'normal',
                color: '#fff'
            },
            // a: {
            //     color: 'gray.100',
            // },
            // 'a:hover': {
            //     color: 'gray.300',
            // },
            // 'a:active': {
            //     color: 'blue.200',
            // },
            'a:visited': {
                color: 'gray.200',
            },

        },
    },
    semanticTokens: {
        colors: {
            text: {
                default: '#16161D',
                _dark: '#ade3b8',
            },
            heroGradientStart: {
                default: '#7928CA',
                _dark: '#e3a7f9',
            },
            heroGradientEnd: {
                default: '#FF0080',
                _dark: '#fbec8f',
            },
        },
        radio: {
            button: '12px',
        },
    },
    colors: {
        black: '#16161D',
    },
},
    // withDefaultColorScheme({ colorScheme: "blackAlpha" }),
    // baseTheme // optional
)

export default theme
